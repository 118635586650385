/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTableOfConcordanceVersion } from '../fn/table-of-concordance/create-table-of-concordance-version';
import { CreateTableOfConcordanceVersion$Params } from '../fn/table-of-concordance/create-table-of-concordance-version';
import { downloadToCWordDocument } from '../fn/table-of-concordance/download-to-c-word-document';
import { DownloadToCWordDocument$Params } from '../fn/table-of-concordance/download-to-c-word-document';
import { getTableOfConcordanceVersionDetails } from '../fn/table-of-concordance/get-table-of-concordance-version-details';
import { GetTableOfConcordanceVersionDetails$Params } from '../fn/table-of-concordance/get-table-of-concordance-version-details';
import { insertToCContent } from '../fn/table-of-concordance/insert-to-c-content';
import { InsertToCContent$Params } from '../fn/table-of-concordance/insert-to-c-content';
import { listTableOfConcordanceVersions } from '../fn/table-of-concordance/list-table-of-concordance-versions';
import { ListTableOfConcordanceVersions$Params } from '../fn/table-of-concordance/list-table-of-concordance-versions';
import { ListToCVersionDetailedResponse } from '../models/list-to-c-version-detailed-response';
import { ListToCVersionsResponse } from '../models/list-to-c-versions-response';
import { ResourceResponse } from '../models/resource-response';
import { updateTableOfConcordanceVersion } from '../fn/table-of-concordance/update-table-of-concordance-version';
import { UpdateTableOfConcordanceVersion$Params } from '../fn/table-of-concordance/update-table-of-concordance-version';
import { updateToCContent } from '../fn/table-of-concordance/update-to-c-content';
import { UpdateToCContent$Params } from '../fn/table-of-concordance/update-to-c-content';


/**
 * actions related to the Table of Concordance
 */
@Injectable({ providedIn: 'root' })
export class TableOfConcordanceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createTableOfConcordanceVersion()` */
  static readonly CreateTableOfConcordanceVersionPath = '/projects/{projectId}/euLaws/{euLawId}/tableOfConcordance/versions';

  /**
   * Create a new version of the Table of Concordance.
   *
   * Create a new version of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTableOfConcordanceVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTableOfConcordanceVersion$Response(params: CreateTableOfConcordanceVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return createTableOfConcordanceVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new version of the Table of Concordance.
   *
   * Create a new version of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTableOfConcordanceVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTableOfConcordanceVersion(params: CreateTableOfConcordanceVersion$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.createTableOfConcordanceVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `getTableOfConcordanceVersionDetails()` */
  static readonly GetTableOfConcordanceVersionDetailsPath = '/projects/{projectId}/euLaws/{euLawId}/tableOfConcordance/versions/{versionId}';

  /**
   * Get Table of Concordance version details.
   *
   * Retrieve the details of a specific version of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTableOfConcordanceVersionDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableOfConcordanceVersionDetails$Response(params: GetTableOfConcordanceVersionDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<ListToCVersionDetailedResponse>> {
    return getTableOfConcordanceVersionDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Table of Concordance version details.
   *
   * Retrieve the details of a specific version of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTableOfConcordanceVersionDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTableOfConcordanceVersionDetails(params: GetTableOfConcordanceVersionDetails$Params, context?: HttpContext): Observable<ListToCVersionDetailedResponse> {
    return this.getTableOfConcordanceVersionDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListToCVersionDetailedResponse>): ListToCVersionDetailedResponse => r.body)
    );
  }

  /** Path part for operation `updateTableOfConcordanceVersion()` */
  static readonly UpdateTableOfConcordanceVersionPath = '/projects/{projectId}/euLaws/{euLawId}/tableOfConcordance/versions/{versionId}';

  /**
   * Update existing version of the Table of Concordance.
   *
   * Update an existing version of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTableOfConcordanceVersion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTableOfConcordanceVersion$Response(params: UpdateTableOfConcordanceVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return updateTableOfConcordanceVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing version of the Table of Concordance.
   *
   * Update an existing version of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTableOfConcordanceVersion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTableOfConcordanceVersion(params: UpdateTableOfConcordanceVersion$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.updateTableOfConcordanceVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `listTableOfConcordanceVersions()` */
  static readonly ListTableOfConcordanceVersionsPath = '/projects/{projectId}/tableOfConcordance/versions';

  /**
   * List all versions of the Table of Concordance.
   *
   * List all versions of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTableOfConcordanceVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTableOfConcordanceVersions$Response(params: ListTableOfConcordanceVersions$Params, context?: HttpContext): Observable<StrictHttpResponse<ListToCVersionsResponse>> {
    return listTableOfConcordanceVersions(this.http, this.rootUrl, params, context);
  }

  /**
   * List all versions of the Table of Concordance.
   *
   * List all versions of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTableOfConcordanceVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTableOfConcordanceVersions(params: ListTableOfConcordanceVersions$Params, context?: HttpContext): Observable<ListToCVersionsResponse> {
    return this.listTableOfConcordanceVersions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListToCVersionsResponse>): ListToCVersionsResponse => r.body)
    );
  }

  /** Path part for operation `updateToCContent()` */
  static readonly UpdateToCContentPath = '/projects/{projectId}/euLaws/{euLawId}/tableOfConcordance/versions/{versionId}/content/{contentId}';

  /**
   * Update content of the Table of Concordance.
   *
   * Update the content of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateToCContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateToCContent$Response(params: UpdateToCContent$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return updateToCContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Update content of the Table of Concordance.
   *
   * Update the content of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateToCContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateToCContent(params: UpdateToCContent$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.updateToCContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `insertToCContent()` */
  static readonly InsertToCContentPath = '/projects/{projectId}/euLaws/{euLawId}/tableOfConcordance/versions/{versionId}/content';

  /**
   * Insert new content to the Table of Concordance.
   *
   * Insert new content to the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `insertToCContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertToCContent$Response(params: InsertToCContent$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return insertToCContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Insert new content to the Table of Concordance.
   *
   * Insert new content to the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `insertToCContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  insertToCContent(params: InsertToCContent$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.insertToCContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `downloadToCWordDocument()` */
  static readonly DownloadToCWordDocumentPath = '/projects/{projectId}/euLaws/{euLawId}/tableOfConcordance/versions/{versionId}/download';

  /**
   * Download word document of the Table of Concordance.
   *
   * Download the word document of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadToCWordDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadToCWordDocument$Response(params: DownloadToCWordDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return downloadToCWordDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Download word document of the Table of Concordance.
   *
   * Download the word document of the Table of Concordance for an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadToCWordDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadToCWordDocument(params: DownloadToCWordDocument$Params, context?: HttpContext): Observable<any> {
    return this.downloadToCWordDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
