import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeSQ from '@angular/common/locales/sq';
registerLocaleData(localeSQ, 'sq');

if (environment.production) {
  let envMode = 'production';

  if (!environment.production && environment.staging) {
    envMode = 'staging';
  } else if (!environment.production && !environment.staging) {
    envMode = 'development';
  }
  
  Sentry.init({
    dsn: 'https://b80c5171932142a328c3d8e497579602@o4506634079961088.ingest.sentry.io/4506634081075200',
    environment: envMode,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
