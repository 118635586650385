import { Injector, ValueProvider } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DYNAMIC_MATCHERS, DynamicFormControlMatcher, DynamicFormService, DynamicSelectModel } from '@ng-dynamic-forms/core';
import { Store } from '@ngrx/store';
import { selectSubchaptersDropdown, selectTopicsDropdown } from '@store/core';
import { selectSyncStore } from '@store/helpers';

export const SUBCHAPTER_MATCHER = 'SUBCHAPTER_MATCHER';
export const TOPIC_MATCHER = 'TOPIC_MATCHER';

type SubChapter = {
  label: string, 
  value: number,
  chapterId: number
}

type Topic = {
  label: string, 
  value: number,
  chapterId: number,
  subchapterId: number
}

export const subchapterMatcher: DynamicFormControlMatcher = {
  match: SUBCHAPTER_MATCHER,
  opposingMatch: '',
  onChange(_, model: DynamicSelectModel<any>, control: FormControl, injector: Injector): void {
    const store = injector.get(Store);
    const service = injector.get(DynamicFormService);
    const roleGroupValue = control.parent?.value;
    if (roleGroupValue?.chapter_id) {
      const subchapters = selectSyncStore<any>(selectSubchaptersDropdown, store);
      model.options = subchapters.filter((el: SubChapter) => el.chapterId === control.parent?.value?.chapter_id);
      model.value = null;
    }
    service.detectChanges();
  },
};

export const topicMatcher: DynamicFormControlMatcher = {
  match: TOPIC_MATCHER,
  opposingMatch: '',
  onChange(_, model: DynamicSelectModel<any>, control: FormControl, injector: Injector): void {
    const store = injector.get(Store);
    const service = injector.get(DynamicFormService);
    const roleGroupValue = control.parent?.value;
    if (roleGroupValue?.sub_chapter_id) {
      const topics = selectSyncStore<any>(selectTopicsDropdown, store);
      model.options = topics.filter((el: Topic) => el.subchapterId === control.parent?.value?.sub_chapter_id);
      model.value = null; 
    } else if (roleGroupValue?.chapter_id) {
      const topics = selectSyncStore<any>(selectTopicsDropdown, store);
      model.options = topics.filter((el: Topic) => el.chapterId === roleGroupValue?.chapter_id);
      model.value = null;
    }
    service.detectChanges();
  },
};

export const CUSTOM_MATCHERS: ValueProvider[] = [
  {
    provide: DYNAMIC_MATCHERS,
    useValue: subchapterMatcher,
    multi: true,
  },
  {
    provide: DYNAMIC_MATCHERS,
    useValue: topicMatcher,
    multi: true,
  },
];
