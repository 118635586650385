import { HttpClientModule } from '@angular/common/http';
import { NgModule, inject } from '@angular/core';
import {
  ApolloClientOptions,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  from,
  split
} from '@apollo/client/core';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { ServiceLocator } from '@core/common/service-locator';
import { GlobalState } from '@core/interfaces/state';
import { chapter_claim, cluster_claim, role_claim } from '@core/interfaces/token';
import { LoggerService } from '@core/services/logger.service';
import { MisAuthService } from '@core/services/mis-auth.service';
import { AuthContext } from '@mis/auth';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/auth';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { createRestartableClient } from './ws';
import { MaliciousCodeLogsService } from '@core/services/malicious-code-log.service';
import * as Sentry from "@sentry/browser";
import { hasInternetConnection } from '@store/layout/layout.selectors';

interface Definintion {
  kind: string;
  operation?: string;
}

export const createApollo = (auth: MisAuthService): ApolloClientOptions<NormalizedCacheObject> => {

  const store: Store<GlobalState> = ServiceLocator.injector.get(Store);
  const _errorLogsService = ServiceLocator.injector.get(MaliciousCodeLogsService);
  let hasInternet: boolean = true;
  let hasInternetConnection$ = store.select(hasInternetConnection);
  hasInternetConnection$.subscribe((hasInternetConnection) => hasInternet = hasInternetConnection );
  let accessToken: AuthContext['accessToken'] | null = null;

  const isTokenValid = () => !!accessToken?.value && !!accessToken?.expiresAt && accessToken?.expiresAt > new Date();

  const isTokenValidOrNull = () => !accessToken || isTokenValid();

  const awaitValidTokenOrNull = () => {
    if (isTokenValidOrNull()) {
      return
    }

    return new Promise((resolve) => {
      // doing this as an interval to avoid race conditions.
      const interval = setInterval(() => {
        if (isTokenValidOrNull()) {
          clearInterval(interval);
          resolve(true);
        }
      }, 100)
    })
  }

  const getAuthHeaders = async () => {
    // wait for valid access token
    await awaitValidTokenOrNull();
    const claims = auth.client.getHasuraClaims();
    // add headers
    let resHeaders = {}
    // add auth headers if signed in
    if (accessToken) {
      Object.assign(resHeaders, {
        authorization: accessToken ? `Bearer ${accessToken.value}` : null,
      })
    }
    if(!accessToken) {
      const token = auth.client.getAccessToken();
      if(token) {
        Object.assign(resHeaders, {
          authorization: token ? `Bearer ${token}` : null,
        })
      }
    }
    if(claims) {
      if(claims[role_claim]) {
        Object.assign(resHeaders, { [role_claim]: claims[role_claim]})
      }
      if(claims[chapter_claim]) {
        Object.assign(resHeaders, { [chapter_claim]: claims[chapter_claim]})
      }
      if(claims[cluster_claim]) {
        Object.assign(resHeaders, { [cluster_claim]: claims[cluster_claim]})
      }
    }

    return resHeaders;
  }

  if (!environment.production) {
    loadDevMessages();
    loadErrorMessages();
  }
  
  const logger = inject(LoggerService);

  const http = createHttpLink({
    uri: 'https://' + environment.hostApiDomain + '/v1/graphql',
  });

  const httpLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(await getAuthHeaders())
      }
    }
  }).concat(http);

  const wsClient = createRestartableClient({
    url: 'wss://' + environment.hostApiDomain + '/v1/graphql',
    shouldRetry: () => true,
    retryAttempts: 100,
    retryWait: async (retries) => {
      // start with 1 second delay
      const baseDelay = 1000;
      // max 3 seconds of jitter
      const maxJitter = 3000;
      // exponential backoff with jitter
      return new Promise((resolve) =>
        setTimeout(
          resolve,
          baseDelay * Math.pow(2, retries) + Math.floor(Math.random() * maxJitter)
        )
      )
    },
    connectionParams: async () => ({
      headers: {
        ...(await getAuthHeaders())
      }
    })
  })

  const ws = new GraphQLWsLink(wsClient);

  const splitLink = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation }: Definintion = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    ws,
    httpLink
  );

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      for (let { message, extensions } of graphQLErrors) {
        logger.log(`[${extensions['code']}]: ${message}`);
      }

    if (networkError) {
      if (!hasInternet) return;
      if (networkError.name === 'TypeError') {
        let object = {
          // ip added on the service
          endpoint: 'https://' + environment.hostApiDomain + '/v1/graphql',
          payload: btoa(
            JSON.stringify({
              operationName: operation.operationName,
              query: operation.query.loc?.source.body,
              variables: operation.variables,
            })
          ),
          response: 'TypeError',
        };
        (async() => {
          try {
            const response = await _errorLogsService.insertErrorLog(object);
            response.subscribe();
          } catch(err) {
            logger.error(err);
          }
        })();
      }
      if (networkError.name !== 'AbortError') {
        logger.log(networkError);
      }
    }

    return forward(operation);
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          pkie_measure: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          pkie_work_plan_task_member: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          gnpieUsersByChapter: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          dms_folder: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          clusters: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          reports: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          nrOfInstitutionsPerChapter: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          nrOfUsersPerInstitution: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          users: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          gnpies: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          chapters: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          sub_chapters: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          capac_experts: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      report_structure: {
        fields: {
          children: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        }
      },
      capac_donators: {
        fields: {
          chapter_donators: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        }
      },
      capac_taiex: {
        fields: {
          training_needs: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        }
      },
      chapters: {
        fields: {
          chapter_lead_institutions: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        }
      },
      users: {
        fields: {
          gnpies: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        }
      },
    },
  });

  const interpreter = auth?.client.client.interpreter;

  interpreter?.onTransition(async (state, event) => {
    if (['SIGNOUT', 'SIGNED_IN', 'TOKEN_CHANGED'].includes(event.type)) {
      if (
        event.type === 'SIGNOUT' ||
        (event.type === 'TOKEN_CHANGED' && state.context.accessToken.value === null)
      ) {
        accessToken = null;
        try {
          wsClient?.terminate()
          // Would be nice to reset the store here, but it's not possible!
        } catch (error) {
          logger.error('Error resetting Apollo client cache')
          logger.error(error)
        }
        return
      }
      
      if (state.context?.dmsToken) {
        store.dispatch(AuthActions.initDmsToken({dmsToken: state.context.dmsToken}));
      }
     
      if(auth.user) {
        try {
          Sentry.setUser({ email: auth.user?.email, id: auth.user?.id });
        } catch(err) {}
        store.dispatch(AuthActions.setUser({ user: auth.user as any }))
      }
      
      // update token
      accessToken = state.context.accessToken;
      if (!wsClient?.isOpen()) {
        return
      }

      wsClient?.restart()
    }
  })

  return {
    link: from([errorLink, splitLink]),
    cache,
    assumeImmutableResults: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  };
};

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [MisAuthService],
    },
  ],
})
export class GraphQLModule {
}
