/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResourceResponse } from '../../models/resource-response';

export interface ApproveArticleTranslation$Params {

/**
 * Unique identifier of the project
 */
  projectId: string;

/**
 * Unique identifier of the EU Law under this project
 */
  lawId: string;

/**
 * Unique identifier of the article in the EU Law which obtained from the Table of Content
 */
  articleId: string;
      body: Array<{

/**
 * Unique identifier of the paragraph in the article
 */
'paragraphId': string;

/**
 * ETag of the resource to be updated. If not provided, server will not check for ETag
 */
'etag'?: string;
}>
}

export function approveArticleTranslation(http: HttpClient, rootUrl: string, params: ApproveArticleTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'results'?: ResourceResponse;
}>> {
  const rb = new RequestBuilder(rootUrl, approveArticleTranslation.PATH, 'put');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('lawId', params.lawId, {});
    rb.path('articleId', params.articleId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'results'?: ResourceResponse;
      }>;
    })
  );
}

approveArticleTranslation.PATH = '/projects/{projectId}/euLaws/{lawId}/translations/{articleId}/approve';
