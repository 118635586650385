import { Injectable } from '@angular/core';
import { GlobalState } from '@core/interfaces/state';
import { Store } from '@ngrx/store';
import { LayoutActions } from '@store/layout';
import { fromEvent, map, merge, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckInternetService {

  constructor(public store: Store<GlobalState>) { }

  checkNetworkStatus() {
    merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.store.dispatch(LayoutActions.setInternetConnection({ status }));
      });
  };

}
