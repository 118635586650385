import { NgModule, Optional, SkipSelf } from '@angular/core';

import { httpInterceptorProviders } from './http-interceptors/index';
import { CheckAccessService } from './services/access.service';

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    const msg = `${moduleName} has already been loaded. Import Core modules in the AppModule only.`;
    throw new Error(msg);
  }
}

@NgModule({
  imports: [],
  providers: [
    CheckAccessService,
    httpInterceptorProviders
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
