import { Maybe, Users } from '@core/api/generated/types';
import { AuthState } from '@core/interfaces/state';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AuthActions = createActionGroup({
  source: 'Authorization',
  events: {
    setUser: props<{ user: Users }>(),
    setChapterId: props<{ chapter_id: number }>(),
    updateAuth: props<{ fields: Partial<AuthState> }>(),
    addRecentUser: props<{ user: Users }>(),
    setDmsId: props<{ dms_id: number }>(),
    initDmsToken: props<{ dmsToken?: Maybe<string> }>(),
    setDmsToken: props<{ csrfToken: string, sessionId: string }>(),
    setApproxToken: props<{ approxToken: string }>(),
    removeApproxToken: emptyProps(),
    setDmsNotAvailable: emptyProps(),
    signOut: emptyProps(),
    resetAuth: emptyProps(),
  },
});
