import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { LoggerService } from '@core/services/logger.service';
import { MisAuthService } from '@core/services/mis-auth.service';
import { from, map, Observable, take } from 'rxjs';
/**
 * Guard to restrict route access to unauthorized users.
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private router: Router, private auth: MisAuthService, private logger: LoggerService) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return from(this.auth.client.isAuthenticatedAsync()).pipe(
      take(1),
      map((isLoggedIn) => {
        if (!isLoggedIn) return this.router.createUrlTree(['/auth/login'], { queryParams: snapshot.queryParams });
        this.logger.log('Authenticated | GO!')
        return isLoggedIn;
      })
    );
  }
}
