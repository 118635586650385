/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EntraIdCollaboratorsList } from '../../models/entra-id-collaborators-list';

export interface QueryCollaborators$Params {

/**
 * Filter Project Collaborators by email or alias, minimum 3 characters to search
 */
  term?: string;

/**
 * Number of items to return per page. Default is 10
 */
  pageSize?: number;
}

export function queryCollaborators(http: HttpClient, rootUrl: string, params?: QueryCollaborators$Params, context?: HttpContext): Observable<StrictHttpResponse<EntraIdCollaboratorsList>> {
  const rb = new RequestBuilder(rootUrl, queryCollaborators.PATH, 'get');
  if (params) {
    rb.query('term', params.term, {});
    rb.query('pageSize', params.pageSize, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EntraIdCollaboratorsList>;
    })
  );
}

queryCollaborators.PATH = '/collaborators';
