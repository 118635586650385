import { createReducer, on } from '@ngrx/store';
import { ProjectRequestBody } from '@core/approx-api/models';
import { ProjectActions } from './project.actions';

export const initialProjectState: Omit<ProjectRequestBody, 'deadlineDate'> & {deadlineDate?: number} = {
  collaborators: [],
  euLaws: [],
  deadlineDate: undefined,
  notes: '',
};

export const projectReducer = createReducer(
  initialProjectState,
  on(ProjectActions.addLaw, (state, { law }) => ({ ...state, euLaws: [...state.euLaws, law] })),
  on(ProjectActions.removeLaw, (state, { id }) => ({ ...state, euLaws: state.euLaws.filter((law) => law.id !== id) })),
  on(ProjectActions.setCollaborators, (state, { collaborators }) => ({ ...state, collaborators })),
  on(ProjectActions.removeCollaborator, (state, { id }) => ({ ...state, collaborators: state.collaborators.filter((collab) => collab.id !== id) })),
  on(ProjectActions.setProps, (state, props) => ({ ...state, ...props })),
  on(ProjectActions.resetProject, () => initialProjectState)
);
