import { AuthRolesEnum, Gnpie } from '@core/api/generated/types';

export const claims_namespace = 'https://hasura.io/jwt/claims';
export const allowed_roles_claim = 'x-hasura-allowed-roles';
export const role_claim = 'x-hasura-role';
export const gnpie_claim = 'x-hasura-gnpies_ids';
export const chapter_claim = 'x-hasura-user-chapter';
export const cluster_claim = 'x-hasura-user-cluster';
export const dms_id_claim = 'x-hasura-dms_id';

export interface AccessToken extends Record<string, unknown> {
  [claims_namespace]: Claims;
  sub: string;
  iat: number;
  exp: number;
}

export interface GNPIE {
  role: string;
  user_id: string;
  chapter_id: number;
}

export interface Claims {
  [gnpie_claim]?: Gnpie[];
  [allowed_roles_claim]: AuthRolesEnum[];
  [dms_id_claim]: number;
  [role_claim]: AuthRolesEnum;
  [chapter_claim]: number;
  'x-hasura-user-id': string;
  'x-hasura-user-email': string;
}
