import { AuthRolesEnum, TypeInstitutionsEnum } from '@core/api';
import { CoreState } from '@core/interfaces/state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Select the feature state from the root state
export const selectCoreState = createFeatureSelector<CoreState>('core');

// Create selectors for clusters, chapters, and subchapters
export const selectClusters = createSelector(selectCoreState, (state) => state.clusters);
export const selectChapters = createSelector(selectCoreState, (state) => state.chapters);
export const selectSubChapters = createSelector(selectCoreState, (state) => state.subchapters);
export const selectTopics = createSelector(selectCoreState, (state) => state.topics);
export const selectDmsGroups = createSelector(selectCoreState, (state) => state.dmsGroups);
export const selectInstitutions = createSelector(selectCoreState, (state) => state.institutions);
// Functional selectors
export const selectChapter = (chapter_id: number) => createSelector(selectCoreState, (state) => state.chapters.find((el) => el.id === chapter_id));
export const selectSubChaptersByChapter = (chapter_id: number) =>
  createSelector(selectCoreState, (state) =>
    state.subchapters.filter((el) => el.chapter_id === chapter_id).sort((a, b) => parseFloat(a.sub_chapter_number!) - parseFloat(b.sub_chapter_number!))
  );
export const selectTopicsBySubchapter = (subchapter_id: number) =>
  createSelector(selectCoreState, (state) =>
    state.topics.filter((el) => el.subchapter_id === subchapter_id).sort((a, b) => parseFloat(a.title!) - parseFloat(b.title!))
  );

export const selectChaptersDropdown = createSelector(selectCoreState, (state) => {
  if (state.chapters) {
    const chapterList = state.chapters.map((chapter) => ({
      label: chapter.chapter_title,
      value: chapter.id,
    }));
    return chapterList;
  } else {
    return [];
  }
});

export const selectClustersDropdown = createSelector(selectCoreState, (state) => {
  if (state.clusters) {
    const chapterList = state.clusters.map((cluster) => ({
      label: cluster.title,
      value: cluster.id,
    }));
    return chapterList;
  } else {
    return [];
  }
});

export const selectSubchaptersDropdown = createSelector(selectCoreState, (state) => {
  if (state.subchapters) {
    const subchapterList = state.subchapters.map((subchapter) => ({
      label: subchapter.title as string | undefined,
      value: subchapter.id,
      chapterId: subchapter.chapter_id,
    }));
    return subchapterList;
  } else {
    return [];
  }
});

export const selectTopicsDropdown = createSelector(selectCoreState, (state) => {
  if (state.topics) {
    const topicsList = state.topics.map((topic) => ({
      label: topic.title,
      value: topic.id,
      chapterId: topic.chapter_id,
      subchapterId: topic.subchapter_id,
    }));
    return topicsList;
  } else {
    return [];
  }
});

export const selectChildInstitutions = (id: string) => createSelector(selectCoreState, (state) => state.institutions.filter((el) => el.parent_id === id));
export const selectMinistries = createSelector(selectCoreState, (state) => state.institutions.filter((el) => el.type === TypeInstitutionsEnum.Qendror));
export const selectSubInst = createSelector(selectCoreState, (state) => state.institutions.filter((el) => el.type === TypeInstitutionsEnum.Varesie));
export const selectIndependentInst = createSelector(selectCoreState, (state) => state.institutions.filter((el) => el.type === TypeInstitutionsEnum.Pavarur));
export const selectInstitutionByQbzId = (qbz_id: number) => createSelector(selectCoreState, (state) => state.institutions.find((el) => el.qbz_id === qbz_id));
export const selectMainDmsGroups = createSelector(selectCoreState, (state) =>
  state.dmsGroups
    .filter((el) =>
      [
        AuthRolesEnum.ChapterCoordinator,
        AuthRolesEnum.GnpieMember,
        AuthRolesEnum.GnpieSecretariat,
        AuthRolesEnum.ChiefGnpieSecretariat,
        AuthRolesEnum.Negotiator,
      ].includes(el.name as any)
    )
    .map((el) => el.id!)
);
export const selectChapterGroups = (chapter_id: number) =>
  createSelector(selectCoreState, (state) =>
    state.dmsGroups
      .filter((el) =>
        [
          [AuthRolesEnum.ChapterCoordinator, chapter_id].join(':'),
          [AuthRolesEnum.GnpieMember, chapter_id].join(':'),
          [AuthRolesEnum.GnpieSecretariat, chapter_id].join(':'),
          [AuthRolesEnum.ChiefGnpieSecretariat, chapter_id].join(':'),
          [AuthRolesEnum.Negotiator, chapter_id].join(':'),
        ].includes(el.name as any)
      )
      .map((el) => el.id!)
  );
