/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResourceResponse } from '../../models/resource-response';
import { UpdateProjectRequestBody } from '../../models/update-project-request-body';

export interface UpdateProject$Params {

/**
 * Unique identifier of the project
 */
  projectId: string;

/**
 * ETag of the resource to be updated
 */
  'If-Match'?: string;
      body: UpdateProjectRequestBody
}

export function updateProject(http: HttpClient, rootUrl: string, params: UpdateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
  const rb = new RequestBuilder(rootUrl, updateProject.PATH, 'put');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.header('If-Match', params['If-Match'], {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResourceResponse>;
    })
  );
}

updateProject.PATH = '/projects/{projectId}';
