/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AiApproxResultItem } from '../../models/ai-approx-result-item';

export interface QueryAiApproximationStatus$Params {

/**
 * Unique identifier of the project
 */
  projectId: string;

/**
 * Unique identifier of the EU Law under this project
 */
  euLawId: string;

/**
 * Unique identifier of the article in the EU Law which obtained from the Table of Content
 */
  articleId: string;

/**
 * Unique identifier of the request to track the status of the approximation
 */
  requestId: string;
}

export function queryAiApproximationStatus(http: HttpClient, rootUrl: string, params: QueryAiApproximationStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;

/**
 * Status of the AI approximation
 */
'runtimeStatus'?: string;

/**
 * Output of the AI approximation
 */
'output'?: Array<{

/**
 * Unique identifier of the paragraph in the article
 */
'paragraphId'?: string;

/**
 * List of AI-generated approximation results
 */
'results'?: Array<AiApproxResultItem>;

/**
 * ETag of the resource after updating the paragraph with the AI approximation
 */
'etag'?: string;
}>;

/**
 * Unix timestamp of when the request was created
 */
'createdTime'?: number;

/**
 * Unix timestamp of when the request was last updated
 */
'lastUpdatedTime'?: number;
}>> {
  const rb = new RequestBuilder(rootUrl, queryAiApproximationStatus.PATH, 'get');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('euLawId', params.euLawId, {});
    rb.path('articleId', params.articleId, {});
    rb.query('requestId', params.requestId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      
      /**
       * Unique identifier of the request to track the status of the approximation
       */
      'requestId'?: string;
      
      /**
       * Status of the AI approximation
       */
      'runtimeStatus'?: string;
      
      /**
       * Output of the AI approximation
       */
      'output'?: Array<{
      
      /**
       * Unique identifier of the paragraph in the article
       */
      'paragraphId'?: string;
      
      /**
       * List of AI-generated approximation results
       */
      'results'?: Array<AiApproxResultItem>;
      
      /**
       * ETag of the resource after updating the paragraph with the AI approximation
       */
      'etag'?: string;
      }>;
      
      /**
       * Unix timestamp of when the request was created
       */
      'createdTime'?: number;
      
      /**
       * Unix timestamp of when the request was last updated
       */
      'lastUpdatedTime'?: number;
      }>;
    })
  );
}

queryAiApproximationStatus.PATH = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/query-status';
