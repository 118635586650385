import { Injectable } from '@angular/core';
import { ErrorLogsInsertInput } from '@core/api';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { LoggerService } from '@core/services/logger.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MisAuthService } from './mis-auth.service';

@Injectable({
  providedIn: 'root'
})
export class MaliciousCodeLogsService {

  ipAddress: string;

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private auth: MisAuthService
  ) { }

  public getIPAddress(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.get("https://api.ipify.org/?format=json")
        .pipe(
          take(1),
          catchError(err => {
            this.loggerService.error(err);
            reject(err);
            return of('Error');
          })
        )
        .subscribe(
          (res: any) => {
            if (typeof res === "object") {
              this.ipAddress = res.ip;
              resolve(res.ip); // Resolve with the IP address
            }
          }
        );
    });
  }

  async insertErrorLog(object: ErrorLogsInsertInput) {
    const ip = await this.getIPAddress();
    let payload = { ...object, ip };

    const query = `
      mutation insertErrorLogs($object: error_logs_insert_input!) {
        insert_error_logs_one(object: $object) {
          id
        }
      }
    `;

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + this.auth.client.getAccessToken());

    const body = {
      query: query,
      variables: {
        object: payload
      }
    };

    return this.http.post('https://' + environment.hostApiDomain + '/v1/graphql', body, { headers })
      .pipe(
        take(1),
        catchError(err => {
          this.loggerService.error(err);
          return of(err);
        })
      );
  }
}