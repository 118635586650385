import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CheckAccessService } from '@core/services/access.service';
import { LoggerService } from '@core/services/logger.service';
import { MisAuthService } from '@core/services/mis-auth.service';
import { FeatureIDs } from '@shared/routing/enums/features';
import { Observable, from, map, take } from 'rxjs';
/**
 * Guard to restrict route access to unauthorized users.
 */
@Injectable({providedIn: 'root'})
export class RoleGuard {

  constructor(private router: Router, private access: CheckAccessService, private auth: MisAuthService, private logger: LoggerService) {}

  canActivate(feature: FeatureIDs): Observable<boolean | UrlTree> {
    return from(this.auth.client.isAuthenticatedAsync()).pipe(
      take(1),
      map(() => {
        // If the user doesn't have a role or chapter assigned, go to chapter selection
        if (!this.auth.role) {
          return this.router.createUrlTree(['/auth/role']);
        } else if(!this.access.checkUserHasAccess(feature)) {
          return this.router.createUrlTree(['/private']);
        }
        this.logger.log(`F:${feature} | GO!`)
        return true;
      })
    );
  }
};

