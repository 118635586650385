import { Injectable } from '@angular/core';
import { FeatureIDs } from '@shared/routing/enums/features';
import { HORIZONTAL_ROLE_MAPPING, MODULE_ROLE_MAPPING, ROLE_MODULE_ACTION_MAPPING } from '../common/application-constants';
import { MisAuthService } from './mis-auth.service';
import { AuthRolesEnum } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class CheckAccessService {
  constructor(private auth: MisAuthService) { }
  /**
   * This will help to check access of module to the logged in user
   * @param feature feature name
   */
  checkUserHasAccess(feature: FeatureIDs): boolean {
    // Check if the user has access to the featureIdToCheck
    let hasAccess: boolean | undefined = false;
    const role = this.auth.role;
    if(!role) return false;
    if (this.auth.role === AuthRolesEnum.HorizontalCoordinator && this.auth.user?.modules?.length) {
      this.auth.user?.modules.forEach((module) => {
        // Check if the module exists in the HORIZONTAL_ROLE_MAPPING
        if (module in HORIZONTAL_ROLE_MAPPING) {
          // Check if the FeatureID is included in the array corresponding to the module
          if (HORIZONTAL_ROLE_MAPPING[module].includes(feature)) {
            hasAccess = true;
            return;
          }
        }
      });
    } else {
      hasAccess = MODULE_ROLE_MAPPING[feature]?.includes(role);
    }
    return hasAccess ?? false;
  }
  /**
   * This will help to check user has operation/action level access on specific topic
   * @param moduleName
   * @param actionSpecified
   */
  canExecuteAction<T extends string>(action: T, feature: FeatureIDs) {
    const role = this.auth.role;
    const map = ROLE_MODULE_ACTION_MAPPING[feature];
    if (map) {
      const roles = map[action];
      if (roles && roles.length && role) {
        return roles.includes(role);
      } else {
        return false
      }
    } else {
      return false
    }
  }
}
