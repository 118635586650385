import { AuthProvider } from "@core/interfaces/auth";

const host = 'mimie.xyz';
export const environment = {
  production: false,
  usesHTTPS: true,
  staging: true,
  logLevel: 1,
  dmsCookiePrefix: '',
  hostApiDomain: ['back', host].join('.'),
  hostSignUpload: ['sign', host].join('.') + '/upload',
  hostSignAuthorize: ['sign', host].join('.') + '/authorize',
  hostAuthDomain: ['auth', host].join('.'),
  hostActionDomain: ['action', host].join('.'),
  hostStorageDomain: [host, '/dms'].join(''),
  webSocketProtocol: 'wss:',
  defaultTimeout: 3000,
  authProviders: [AuthProvider.NAIS_B2C, AuthProvider.OIDC],
  secret: 'w/@vhT9yQZe{mR[',
  msalClientId: '3d760804-6339-4600-87cb-e50553f42882',
  msalAuthority: 'f5d8b812-606a-42ba-8cf9-3371cfe29c72',
  msalRedirectUri: 'https://mimie.xyz/approx',
  msalPostLogoutRedirectUri: 'https://mimie.xyz/auth/login',
  approxUrl: 'https://app-aiapprox-gwc-01.azurewebsites.net/api/v1',
};
