import { Async, StateStorage } from '@ngrx-addons/persist-state';
import * as CryptoJS from 'crypto-js';
import * as localforage from 'localforage';
import { environment } from 'src/environments/environment';

interface StateStorageInterface {
  getItem(key: string): Promise<string | null>;
  setItem(key: string, value: string | object | null): Promise<string | object | null>;
  removeItem(key: string): Promise<void>;
}

const noopStorage: StateStorage = {
  getItem: () => Promise.resolve(null),
  setItem: () => Promise.resolve(null),
  removeItem: () => Promise.resolve(),
}

export const createSecureStorage = (storage: LocalForage | undefined): StateStorage => {
  if (!storage) {
    return noopStorage;
  }
  return {
    getItem: <T>(key: string): Async<T | null | undefined> => {
      return getItem(key, storage);
    },
    setItem: (key: string, value: Record<string, unknown>): Async<void> => {
      return setItem(key, value, storage);
    },
    removeItem: (key: string): Async<boolean | void> => {
      return removeItem(key, storage);
    },
  };
};

const getItem = <T>(key: string, storage: StateStorageInterface): Promise<T | null> => {
  return new Promise(async (resolve) => {
    try {
      const encoded = await storage.getItem(key);
      if (encoded) {
        const data = CryptoJS.AES.decrypt(encoded, environment.secret || 'A3PnGzWjxGuAYtFu');
        const decrypted = data.toString(CryptoJS.enc.Utf8);
        resolve(JSON.parse(decrypted));
      } else {
        resolve(null);
      }
    } catch(e) {
      resolve(null);
    }
  });
}

const setItem = (key: string, value: any, storage: StateStorageInterface): Promise<void> => {
  return new Promise((resolve, reject) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), environment.secret || 'A3PnGzWjxGuAYtFu').toString();
    storage
      .setItem(key, encrypted)
      .then(() => resolve())
      .catch(() => reject());
  });
}

const removeItem = (key: string, storage: StateStorageInterface): Promise<void> => {
  return new Promise((resolve, reject) => {
    storage
      .removeItem(key)
      .then(() => resolve())
      .catch(() => reject());
  });
}

const storage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: 'cache',
  version: 1.0,
  storeName: 'store',
  description: 'NgRx store layer cache with encrypted data!',
})

export const secureStorageStrategy = createSecureStorage(storage);
