import { CoreState } from '@core/interfaces/state';
import { createReducer, on } from '@ngrx/store';
import { CoreActions } from './core.actions';

export const initialCoreState: CoreState = {
  clusters: [],
  chapters: [],
  subchapters: [],
  topics: [],
  dmsGroups: [],
  institutions: [],
  dmsTasks: [],
}

export const coreReducer = createReducer(
  initialCoreState,
  on(CoreActions.setClusters, (state, {clusters}) => ({...state, clusters})),
  on(CoreActions.setChapters, (state, {chapters}) => ({...state, chapters})),
  on(CoreActions.setSubChapters, (state, {subchapters}) => ({...state, subchapters})),
  on(CoreActions.setTopics, (state, {topics}) => ({...state, topics})),
  on(CoreActions.setInstitutions, (state, {institutions}) => ({...state, institutions})),
  on(CoreActions.insertInstitution, (state, {institution}) => ({...state, institutions: [...state.institutions, institution]})),
  on(CoreActions.updateInstitution, (state, {institution}) => ({...state, institutions: state.institutions.map(i => i.id === institution.id ? institution : i)})),
  on(CoreActions.setDmsGroups, (state, {dmsGroups}) => ({...state, dmsGroups})),
  on(CoreActions.resetCore, () => initialCoreState),
  on(CoreActions.changeChapterPhase, (state, {chapterId, phase}) => ({...state, chapters: state.chapters.map(chapter => chapter.id === chapterId ? {...chapter, phase} : chapter)})),
  on(CoreActions.deleteSubchapter, (state, {id}) => ({...state, subchapters: state.subchapters.filter(subchapter => subchapter.id !== id)})),
  on(CoreActions.deleteTopic, (state, {id}) => ({...state, topics: state.topics.filter(topic => topic.id !== id)})),
  on(CoreActions.addSubchapter, (state, {subchapter}) => ({...state, subchapters: [...state.subchapters, subchapter]})),
  on(CoreActions.addTopic, (state, {topic}) => ({...state, topics: [...state.topics, topic]})),
)