/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ListAlbanianLawsResponse } from '../../models/list-albanian-laws-response';

export interface QueryAlbanianLaws$Params {

/**
 * Filter Albanian Laws by name (Partial match)
 */
  name?: string;

/**
 * Return projects starting from the provided page number. Default page size is 10
 */
  pageNo?: number;

/**
 * Number of items to return per page. Default is 10
 */
  pageSize?: number;
}

export function queryAlbanianLaws(http: HttpClient, rootUrl: string, params?: QueryAlbanianLaws$Params, context?: HttpContext): Observable<StrictHttpResponse<ListAlbanianLawsResponse>> {
  const rb = new RequestBuilder(rootUrl, queryAlbanianLaws.PATH, 'get');
  if (params) {
    rb.query('name', params.name, {});
    rb.query('pageNo', params.pageNo, {});
    rb.query('pageSize', params.pageSize, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ListAlbanianLawsResponse>;
    })
  );
}

queryAlbanianLaws.PATH = '/albanianLaws';
