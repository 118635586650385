/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface DownloadToCWordDocument$Params {

/**
 * The ID of the project
 */
  projectId: string;

/**
 * EU Law ID
 */
  euLawId: string;

/**
 * Table of Concordance version ID
 */
  versionId: string;
}

export function downloadToCWordDocument(http: HttpClient, rootUrl: string, params: DownloadToCWordDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
  const rb = new RequestBuilder(rootUrl, downloadToCWordDocument.PATH, 'get');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('euLawId', params.euLawId, {});
    rb.path('versionId', params.versionId, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<any>;
    })
  );
}

downloadToCWordDocument.PATH = '/projects/{projectId}/euLaws/{euLawId}/tableOfConcordance/versions/{versionId}/download';
