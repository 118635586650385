/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TranslationContentResponse } from '../../models/translation-content-response';

export interface GetArticleTranslation$Params {

/**
 * Unique identifier of the project
 */
  projectId: string;

/**
 * Unique identifier of the EU Law under this project
 */
  lawId: string;

/**
 * Unique identifier of the article in the EU Law which obtained from the Table of Content
 */
  articleId: string;
}

export function getArticleTranslation(http: HttpClient, rootUrl: string, params: GetArticleTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<TranslationContentResponse>> {
  const rb = new RequestBuilder(rootUrl, getArticleTranslation.PATH, 'get');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('lawId', params.lawId, {});
    rb.path('articleId', params.articleId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TranslationContentResponse>;
    })
  );
}

getArticleTranslation.PATH = '/projects/{projectId}/euLaws/{lawId}/translations/{articleId}';
