import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { MisAuthService } from '@core/services/mis-auth.service';

/**
 * Guard to restrict route access to unauthorized users.
 */

@Injectable({providedIn: 'root'})
export class SelectRoleGuard {
  constructor(private router: Router, private auth: MisAuthService) {}

  canActivate(): boolean | UrlTree {
    if (this.auth.role) {
      return this.router.createUrlTree(['/private/dashboard']);
    } else {
      return true;
    }
  }
};

