/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ListAlbanianLawsResponse } from '../models/list-albanian-laws-response';
import { queryAlbanianLaws } from '../fn/albanian-laws/query-albanian-laws';
import { QueryAlbanianLaws$Params } from '../fn/albanian-laws/query-albanian-laws';


/**
 * Actions related to Albanian Laws listing and filtering
 */
@Injectable({ providedIn: 'root' })
export class AlbanianLawsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `queryAlbanianLaws()` */
  static readonly QueryAlbanianLawsPath = '/albanianLaws';

  /**
   * List and filter Albanian Laws.
   *
   * Query existing Albanian Laws and filter them
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryAlbanianLaws()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAlbanianLaws$Response(params?: QueryAlbanianLaws$Params, context?: HttpContext): Observable<StrictHttpResponse<ListAlbanianLawsResponse>> {
    return queryAlbanianLaws(this.http, this.rootUrl, params, context);
  }

  /**
   * List and filter Albanian Laws.
   *
   * Query existing Albanian Laws and filter them
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryAlbanianLaws$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAlbanianLaws(params?: QueryAlbanianLaws$Params, context?: HttpContext): Observable<ListAlbanianLawsResponse> {
    return this.queryAlbanianLaws$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListAlbanianLawsResponse>): ListAlbanianLawsResponse => r.body)
    );
  }

}
