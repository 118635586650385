import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LayoutActions = createActionGroup({
  source: 'Layout',
  events: {
    toggleMainLoader: props<{ show: boolean }>(),
    toggleTheme: props<{ theme: 'light' | 'dark' | 'system' }>(),
    toggleAnimation: props<{ animation: string }>(),
    toggleSidebar: emptyProps(),
    toggleLocale: props<{ locale: 'en' | 'al' }>(),
    setInternetConnection: props<{ status: boolean }>(),
  },
});