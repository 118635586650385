import { AfterViewInit, Component, OnInit, ViewContainerRef } from '@angular/core';
import { SettingsService } from '@core/documents/services/settings.service';
import { ModalService } from '@core/modal/modal.service';
import { CheckInternetService } from '@core/services/check-internet.service';
import { Apollo } from 'apollo-angular';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private vc: ViewContainerRef, 
    private modal: ModalService,
    private dmsSettings: SettingsService,
    private apollo: Apollo, // Do not remove this line
    private _checkInternetService: CheckInternetService
  ) {}

  ngOnInit(): void {
    initFlowbite();
    this.dmsSettings.init().subscribe();
    this._checkInternetService.checkNetworkStatus();
  }

  ngAfterViewInit(): void {
    this.modal.view = this.vc;
  }
}
