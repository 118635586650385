/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ListToCVersionDetailedResponse } from '../../models/list-to-c-version-detailed-response';

export interface GetTableOfConcordanceVersionDetails$Params {

/**
 * The ID of the project
 */
  projectId: string;

/**
 * EU Law ID
 */
  euLawId: string;

/**
 * Table of Concordance version ID
 */
  versionId: string;

/**
 * Number of items to return per page. Default is 100
 */
  pageSize?: number;

/**
 * Return projects starting from the provided page number. Default page size is 1
 */
  pageNo?: number;
}

export function getTableOfConcordanceVersionDetails(http: HttpClient, rootUrl: string, params: GetTableOfConcordanceVersionDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<ListToCVersionDetailedResponse>> {
  const rb = new RequestBuilder(rootUrl, getTableOfConcordanceVersionDetails.PATH, 'get');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('euLawId', params.euLawId, {});
    rb.path('versionId', params.versionId, {});
    rb.query('pageSize', params.pageSize, {});
    rb.query('pageNo', params.pageNo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ListToCVersionDetailedResponse>;
    })
  );
}

getTableOfConcordanceVersionDetails.PATH = '/projects/{projectId}/euLaws/{euLawId}/tableOfConcordance/versions/{versionId}';
