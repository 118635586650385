/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AssociatedAlbanianLaw } from '../../models/associated-albanian-law';
import { UploadDraftAlbanianLawRequestBody } from '../../models/upload-draft-albanian-law-request-body';

export interface UploadDraftsAlbanianLaws$Params {

/**
 * Unique identifier of the project
 */
  projectId: string;
  
    /**
     * Draft Albanian Law  to be uploaded and associated with EU Law in the project
     */
    body: UploadDraftAlbanianLawRequestBody
}

export function uploadDraftsAlbanianLaws(http: HttpClient, rootUrl: string, params: UploadDraftsAlbanianLaws$Params, context?: HttpContext): Observable<StrictHttpResponse<AssociatedAlbanianLaw>> {
  const rb = new RequestBuilder(rootUrl, uploadDraftsAlbanianLaws.PATH, 'post');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AssociatedAlbanianLaw>;
    })
  );
}

uploadDraftsAlbanianLaws.PATH = '/projects/{projectId}/albanianLaws/drafts/upload';
