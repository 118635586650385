/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AppRole } from '../models/app-role';
import { queryAppRoles } from '../fn/configurations/query-app-roles';
import { QueryAppRoles$Params } from '../fn/configurations/query-app-roles';


/**
 * Actions related to the Solution Configurations
 */
@Injectable({ providedIn: 'root' })
export class ConfigurationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `queryAppRoles()` */
  static readonly QueryAppRolesPath = '/configurations/appRoles';

  /**
   * List Application Roles.
   *
   * Retrieve the list of application roles available in the system
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryAppRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAppRoles$Response(params?: QueryAppRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AppRole>>> {
    return queryAppRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * List Application Roles.
   *
   * Retrieve the list of application roles available in the system
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryAppRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAppRoles(params?: QueryAppRoles$Params, context?: HttpContext): Observable<Array<AppRole>> {
    return this.queryAppRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AppRole>>): Array<AppRole> => r.body)
    );
  }

}
