import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards';

export const routes: Routes = [
    { 
        path: '', redirectTo: 'auth/login', pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('src/app/modules/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'private',
        loadChildren: () => import('src/app/layouts/main/layout.module').then(m => m.MainLayoutModule),
        canActivate: [(snapshot: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(snapshot)],
    },
    {
        path: '**',
        loadComponent: () => import('src/app/modules/error/error404/error404').then(c => c.Error404Component)
    }
];
