/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApproximationComment } from '../../models/approximation-comment';

export interface GetCommentsOnApproximation$Params {

/**
 * The ID of the project
 */
  projectId: string;

/**
 * EU Law ID
 */
  euLawId: string;

/**
 * Article ID
 */
  articleId: string;

/**
 * Paragraph ID
 */
  paragraphId: string;
}

export function getCommentsOnApproximation(http: HttpClient, rootUrl: string, params: GetCommentsOnApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApproximationComment>>> {
  const rb = new RequestBuilder(rootUrl, getCommentsOnApproximation.PATH, 'get');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('euLawId', params.euLawId, {});
    rb.path('articleId', params.articleId, {});
    rb.path('paragraphId', params.paragraphId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ApproximationComment>>;
    })
  );
}

getCommentsOnApproximation.PATH = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/comments';
