import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { MisAuthService } from '@core/services/mis-auth.service';
import { Observable, from, map, take } from 'rxjs';
/**
 * Guard to restrict route access to authorized users.
 */
@Injectable({ providedIn: 'root' })
export class UnAuthGuard {
  constructor(private router: Router, private auth: MisAuthService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return from(this.auth.client.isAuthenticatedAsync()).pipe(
      take(1),
      map((isLoggedIn) => {
        if (isLoggedIn) return this.router.createUrlTree(['/private/dashboard']);
        return true;
      })
    );
  }
}

