import { Injectable } from '@angular/core';
import { captureException } from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import { format } from 'date-fns';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Store } from '@ngrx/store';
import { GlobalState } from '@core/interfaces/state';
import { LayoutActions, hasInternetConnection } from '@store/layout';
import { from, map, take } from 'rxjs';
import { MisAuthService } from './mis-auth.service';
import { TranslateService } from '@ngx-translate/core';
/**
 * If require we can send errors to the server
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  hasInternetConnection$ = this.store.select(hasInternetConnection);
  hasInternet: boolean = false;

  constructor(private store: Store<GlobalState>, private auth: MisAuthService, private translate: TranslateService) {
    this.hasInternetConnection$.subscribe((hasInternetConnection) => this.hasInternet = hasInternetConnection );
  }

  log(error: any): void {
    if (environment.logLevel === 2) {
      // We are in development
      console.log(error);
    }
  }

  error(error: any): void {
    this.store.dispatch(LayoutActions.toggleMainLoader({ show: false }));
    if (environment.logLevel === 2) {
      // We are in development
      console.error(error);
    } else if (environment.logLevel === 1) {
      // We are in staging
      console.warn(error);
      captureException(error, {
        level: 'error',
        user: {
          id: this.auth.user?.id,
        },
      });
    } else {
      if(!this.hasInternet) return;
      const id = format(new Date(), 'Ldd-hms');
      from(this.showAlert(id))
        .pipe(
          map((incidentId) => ({user: this.auth.user, incidentId}))
        )
        .pipe(take(1))
        .subscribe({
          next: ({ user, incidentId }) => {
            captureException(error, {
              user: {
                id: user?.id,
              },
              level: 'error',
              extra: {
                incidentId,
              },
            });
          },
        });
    }
  }

  showWarningModal(title: string, text: string, confirmButtonText?: string) {
    return Swal.fire({
      icon: 'warning',
      title,
      text,
      showCancelButton: true,
      showConfirmButton: !!confirmButtonText,
      confirmButtonColor: '#e7515a',
      cancelButtonText: this.translate.instant('SHARED.go_back'),
      confirmButtonText,
      padding: '3em',
      customClass: 'sweet-alerts max-w-md',
    })
  }

  showErrorModal(title: string, text: string, confirmButtonText?: string) {
    return Swal.fire({
      icon: 'error',
      title,
      text,
      showCancelButton: true,
      showConfirmButton: !!confirmButtonText,
      confirmButtonColor: '#e7515a',
      cancelButtonText: this.translate.instant('SHARED.go_back'),
      confirmButtonText,
      padding: '3em',
      customClass: 'sweet-alerts max-w-md',
    })
  }

  showInfoModal(title: string, text: string, confirmButtonText?: string) {
    return Swal.fire({
      icon: 'info',
      title,
      text,
      showCancelButton: true,
      showConfirmButton: !!confirmButtonText,
      confirmButtonColor: '#4361ee',
      cancelButtonText: this.translate.instant('SHARED.go_back'),
      confirmButtonText,
      padding: '3em',
      customClass: 'sweet-alerts max-w-md',
    })
  }
  
  showError(text: string) {
    this.show({icon: 'error', text});
  }

  showInfo(text: string) {
    this.show({icon: 'success', text});
  }

  private show(options: SweetAlertOptions) {
    const toast = Swal.mixin({
      position: 'top-end',
      showCloseButton: true,
      showConfirmButton: false,
      padding: '1em',
      customClass: 'sweet-alerts',
    });
    toast.fire({
      icon: options.icon,
      title: options.text,
      toast: true,
      timer: 5000,
      timerProgressBar: true,
      padding: '1em',
      customClass: 'sweet-alerts',
    });
  }

  private async showAlert(id: string) {
    const toast = Swal.mixin({
      showCloseButton: true,
      showConfirmButton: false,
      padding: '1em',
      customClass: 'sweet-alerts',
    });
    await toast.fire({
      icon: 'error',
      title: this.translate.instant('SHARED.generic_problem'),
      text: 'Incident ID: ' + id,
      padding: '1em',
      customClass: 'sweet-alerts',
    });
    return id;
  }
}
