import { LayoutState } from '@core/interfaces/state';
import { createReducer, on } from '@ngrx/store';
import { $themeConfig } from 'src/app/theme.config';
import { LayoutActions } from './layout.actions';

export const initialLayoutState: LayoutState = {
  isDarkMode: false,
  theme: $themeConfig.theme,
  menu: $themeConfig.menu,
  animation: $themeConfig.animation,
  navbar: $themeConfig.navbar,
  locale: $themeConfig.locale,
  sidebar: false,
  languageList: [
    { code: 'al', name: 'Shqip' },
    { code: 'en', name: 'English' },
  ],
  isShowMainLoader: true,
  hasInternetConnection: true,
};

export const layoutReducer = createReducer(
  initialLayoutState,
  on(LayoutActions.toggleAnimation, (state, { animation }) => {
    if (animation) {
      const eleanimation: any = document.querySelector('.animation');
      eleanimation?.classList.add('animate__animated');
      eleanimation?.classList.add(animation);
    }
    return { ...state, ...{ animation } };
  }),
  on(LayoutActions.toggleTheme, (state, { theme }) => {
    localStorage.setItem('theme', theme);
    let isDarkMode = state.isDarkMode || false;
    if (theme == 'light') {
      isDarkMode = false;
    } else if (theme == 'dark') {
      isDarkMode = true;
    } else if (theme == 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        isDarkMode = true;
      } else {
        isDarkMode = false;
      }
    }

    if (isDarkMode) {
      document.querySelector('body')?.classList.add('dark');
    } else {
      document.querySelector('body')?.classList.remove('dark');
    }
    return { ...state, theme, isDarkMode };
  }),
  on(LayoutActions.toggleLocale, (state, { locale }) => {
    localStorage.setItem('i18n_locale', locale);
    return { ...state, locale };
  }),
  on(LayoutActions.toggleSidebar, state => ({...state, sidebar: !state.sidebar})),
  on(LayoutActions.toggleMainLoader, (state, {show}) => ({...state, isShowMainLoader: show})),
  on(LayoutActions.setInternetConnection, (state, {status}) => ({...state, hasInternetConnection: status})),
);