import { Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { MODAL_DATA } from './variables';

@Injectable({
  providedIn: 'root'
})

export class ModalService {

  view!: ViewContainerRef;

  constructor(private injector: Injector) {}

  openModal<T>(component: Type<T>, data?: any) {
    this.view.clear();
    // Create an Injector with the data provided using the custom InjectionToken
    const dataInjector = Injector.create({
      providers: [{ provide: MODAL_DATA, useValue: data }],
      parent: this.injector,
    });
    return this.view.createComponent(component, {injector: dataInjector}).instance;
  }

  closeModal() {
    this.view.clear();
  }

}
