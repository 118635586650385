/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ListEuLawsResponse } from '../../models/list-eu-laws-response';

export interface QueryEuLaws$Params {

/**
 * Filter EU Laws by name (Partial match)
 */
  name?: string;

/**
 * Filter EU Laws by chapter
 */
  chapter?: string;

/**
 * Filter EU Laws by subchapter
 */
  subchapter?: string;

/**
 * Filter EU Laws by CELEX (Exact match)
 */
  celex?: string;

/**
 * Return EU Laws starting from the provided page number. Default page size is 10
 */
  pageNo?: number;
}

export function queryEuLaws(http: HttpClient, rootUrl: string, params?: QueryEuLaws$Params, context?: HttpContext): Observable<StrictHttpResponse<ListEuLawsResponse>> {
  const rb = new RequestBuilder(rootUrl, queryEuLaws.PATH, 'get');
  if (params) {
    rb.query('name', params.name, {});
    rb.query('chapter', params.chapter, {});
    rb.query('subchapter', params.subchapter, {});
    rb.query('celex', params.celex, {});
    rb.query('pageNo', params.pageNo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ListEuLawsResponse>;
    })
  );
}

queryEuLaws.PATH = '/euLaws';
