/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChapterHierarchyResponse } from '../models/chapter-hierarchy-response';
import { ListEuLawsResponse } from '../models/list-eu-laws-response';
import { queryEuLaws } from '../fn/eu-laws/query-eu-laws';
import { QueryEuLaws$Params } from '../fn/eu-laws/query-eu-laws';
import { queryEuLawsChapters } from '../fn/eu-laws/query-eu-laws-chapters';
import { QueryEuLawsChapters$Params } from '../fn/eu-laws/query-eu-laws-chapters';


/**
 * Actions related to EU Laws listing and filtering
 */
@Injectable({ providedIn: 'root' })
export class EuLawsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `queryEuLaws()` */
  static readonly QueryEuLawsPath = '/euLaws';

  /**
   * List and filter EU Laws.
   *
   * Query existing EU Laws and filter them based on various criteria
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryEuLaws()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryEuLaws$Response(params?: QueryEuLaws$Params, context?: HttpContext): Observable<StrictHttpResponse<ListEuLawsResponse>> {
    return queryEuLaws(this.http, this.rootUrl, params, context);
  }

  /**
   * List and filter EU Laws.
   *
   * Query existing EU Laws and filter them based on various criteria
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryEuLaws$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryEuLaws(params?: QueryEuLaws$Params, context?: HttpContext): Observable<ListEuLawsResponse> {
    return this.queryEuLaws$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListEuLawsResponse>): ListEuLawsResponse => r.body)
    );
  }

  /** Path part for operation `queryEuLawsChapters()` */
  static readonly QueryEuLawsChaptersPath = '/euLaws/chapters';

  /**
   * List EU Laws Chapters and Subchapters.
   *
   * Retrieve existing EU Laws and list their chapters and subchapters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryEuLawsChapters()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryEuLawsChapters$Response(params?: QueryEuLawsChapters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChapterHierarchyResponse>>> {
    return queryEuLawsChapters(this.http, this.rootUrl, params, context);
  }

  /**
   * List EU Laws Chapters and Subchapters.
   *
   * Retrieve existing EU Laws and list their chapters and subchapters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryEuLawsChapters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryEuLawsChapters(params?: QueryEuLawsChapters$Params, context?: HttpContext): Observable<Array<ChapterHierarchyResponse>> {
    return this.queryEuLawsChapters$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChapterHierarchyResponse>>): Array<ChapterHierarchyResponse> => r.body)
    );
  }

}
