/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AiApproxResultItem } from '../models/ai-approx-result-item';
import { appendCommentToApproximation } from '../fn/approximations/append-comment-to-approximation';
import { AppendCommentToApproximation$Params } from '../fn/approximations/append-comment-to-approximation';
import { appendHumanApproximation } from '../fn/approximations/append-human-approximation';
import { AppendHumanApproximation$Params } from '../fn/approximations/append-human-approximation';
import { ApproximationComment } from '../models/approximation-comment';
import { ApproximationContent } from '../models/approximation-content';
import { deleteExistingHumanApproximation } from '../fn/approximations/delete-existing-human-approximation';
import { DeleteExistingHumanApproximation$Params } from '../fn/approximations/delete-existing-human-approximation';
import { EuLawSummary } from '../models/eu-law-summary';
import { getArticleApproximation } from '../fn/approximations/get-article-approximation';
import { GetArticleApproximation$Params } from '../fn/approximations/get-article-approximation';
import { getCommentsOnApproximation } from '../fn/approximations/get-comments-on-approximation';
import { GetCommentsOnApproximation$Params } from '../fn/approximations/get-comments-on-approximation';
import { getHumanApproximationHistory } from '../fn/approximations/get-human-approximation-history';
import { GetHumanApproximationHistory$Params } from '../fn/approximations/get-human-approximation-history';
import { HumanApproxResultItem } from '../models/human-approx-result-item';
import { queryAiApproximationStatus } from '../fn/approximations/query-ai-approximation-status';
import { QueryAiApproximationStatus$Params } from '../fn/approximations/query-ai-approximation-status';
import { ResourceResponse } from '../models/resource-response';
import { setApproximationLevel } from '../fn/approximations/set-approximation-level';
import { SetApproximationLevel$Params } from '../fn/approximations/set-approximation-level';
import { triggerAiApproximation } from '../fn/approximations/trigger-ai-approximation';
import { TriggerAiApproximation$Params } from '../fn/approximations/trigger-ai-approximation';
import { updateCommentOnApproximation } from '../fn/approximations/update-comment-on-approximation';
import { UpdateCommentOnApproximation$Params } from '../fn/approximations/update-comment-on-approximation';
import { updateExistingHumanApproximation } from '../fn/approximations/update-existing-human-approximation';
import { UpdateExistingHumanApproximation$Params } from '../fn/approximations/update-existing-human-approximation';


/**
 * Actions related to EU Laws Approximation to Albanian Laws under a certain project
 */
@Injectable({ providedIn: 'root' })
export class ApproximationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getArticleApproximation()` */
  static readonly GetArticleApproximationPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}';

  /**
   * Get approximation for an EU law article.
   *
   * Retrieve the approximation details for a specific article in an EU Law associated with a specific Project (Transposition)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticleApproximation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleApproximation$Response(params: GetArticleApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<{

/**
 * Unique identifier of the Article
 */
'id'?: string;

/**
 * The ID of the project
 */
'projectId'?: string;
'euLaw'?: EuLawSummary;

/**
 * Title of the Article
 */
'title'?: string;

/**
 * Display name of the article
 */
'displayName'?: string;

/**
 * Approximation content (paragraphs) for the article
 */
'content'?: Array<ApproximationContent>;
}>> {
    return getArticleApproximation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get approximation for an EU law article.
   *
   * Retrieve the approximation details for a specific article in an EU Law associated with a specific Project (Transposition)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticleApproximation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleApproximation(params: GetArticleApproximation$Params, context?: HttpContext): Observable<{

/**
 * Unique identifier of the Article
 */
'id'?: string;

/**
 * The ID of the project
 */
'projectId'?: string;
'euLaw'?: EuLawSummary;

/**
 * Title of the Article
 */
'title'?: string;

/**
 * Display name of the article
 */
'displayName'?: string;

/**
 * Approximation content (paragraphs) for the article
 */
'content'?: Array<ApproximationContent>;
}> {
    return this.getArticleApproximation$Response(params, context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Unique identifier of the Article
 */
'id'?: string;

/**
 * The ID of the project
 */
'projectId'?: string;
'euLaw'?: EuLawSummary;

/**
 * Title of the Article
 */
'title'?: string;

/**
 * Display name of the article
 */
'displayName'?: string;

/**
 * Approximation content (paragraphs) for the article
 */
'content'?: Array<ApproximationContent>;
}>): {

/**
 * Unique identifier of the Article
 */
'id'?: string;

/**
 * The ID of the project
 */
'projectId'?: string;
'euLaw'?: EuLawSummary;

/**
 * Title of the Article
 */
'title'?: string;

/**
 * Display name of the article
 */
'displayName'?: string;

/**
 * Approximation content (paragraphs) for the article
 */
'content'?: Array<ApproximationContent>;
} => r.body)
    );
  }

  /** Path part for operation `triggerAiApproximation()` */
  static readonly TriggerAiApproximationPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/trigger';

  /**
   * Trigger approximation for a list of paragraphs within an article.
   *
   * Trigger AI approximation in async manner for a list of paragraphs within an article of an EU Law associated with a specific Project. Result is an **Request Id** to track the status of the approximation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerAiApproximation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerAiApproximation$Response(params: TriggerAiApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<{

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;
}>> {
    return triggerAiApproximation(this.http, this.rootUrl, params, context);
  }

  /**
   * Trigger approximation for a list of paragraphs within an article.
   *
   * Trigger AI approximation in async manner for a list of paragraphs within an article of an EU Law associated with a specific Project. Result is an **Request Id** to track the status of the approximation
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `triggerAiApproximation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerAiApproximation(params: TriggerAiApproximation$Params, context?: HttpContext): Observable<{

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;
}> {
    return this.triggerAiApproximation$Response(params, context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;
}>): {

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;
} => r.body)
    );
  }

  /** Path part for operation `queryAiApproximationStatus()` */
  static readonly QueryAiApproximationStatusPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/query-status';

  /**
   * Query/Poll the status of AI approximation.
   *
   * Query the status of AI approximation for a list of paragraphs within an article of an EU Law associated with a specific Project. The request ID is obtained from the trigger AI approximation endpoint
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryAiApproximationStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAiApproximationStatus$Response(params: QueryAiApproximationStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;

/**
 * Status of the AI approximation
 */
'runtimeStatus'?: string;

/**
 * Output of the AI approximation
 */
'output'?: Array<{

/**
 * Unique identifier of the paragraph in the article
 */
'paragraphId'?: string;

/**
 * List of AI-generated approximation results
 */
'results'?: Array<AiApproxResultItem>;

/**
 * ETag of the resource after updating the paragraph with the AI approximation
 */
'etag'?: string;
}>;

/**
 * Unix timestamp of when the request was created
 */
'createdTime'?: number;

/**
 * Unix timestamp of when the request was last updated
 */
'lastUpdatedTime'?: number;
}>> {
    return queryAiApproximationStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Query/Poll the status of AI approximation.
   *
   * Query the status of AI approximation for a list of paragraphs within an article of an EU Law associated with a specific Project. The request ID is obtained from the trigger AI approximation endpoint
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryAiApproximationStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAiApproximationStatus(params: QueryAiApproximationStatus$Params, context?: HttpContext): Observable<{

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;

/**
 * Status of the AI approximation
 */
'runtimeStatus'?: string;

/**
 * Output of the AI approximation
 */
'output'?: Array<{

/**
 * Unique identifier of the paragraph in the article
 */
'paragraphId'?: string;

/**
 * List of AI-generated approximation results
 */
'results'?: Array<AiApproxResultItem>;

/**
 * ETag of the resource after updating the paragraph with the AI approximation
 */
'etag'?: string;
}>;

/**
 * Unix timestamp of when the request was created
 */
'createdTime'?: number;

/**
 * Unix timestamp of when the request was last updated
 */
'lastUpdatedTime'?: number;
}> {
    return this.queryAiApproximationStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;

/**
 * Status of the AI approximation
 */
'runtimeStatus'?: string;

/**
 * Output of the AI approximation
 */
'output'?: Array<{

/**
 * Unique identifier of the paragraph in the article
 */
'paragraphId'?: string;

/**
 * List of AI-generated approximation results
 */
'results'?: Array<AiApproxResultItem>;

/**
 * ETag of the resource after updating the paragraph with the AI approximation
 */
'etag'?: string;
}>;

/**
 * Unix timestamp of when the request was created
 */
'createdTime'?: number;

/**
 * Unix timestamp of when the request was last updated
 */
'lastUpdatedTime'?: number;
}>): {

/**
 * Unique identifier of the request to track the status of the approximation
 */
'requestId'?: string;

/**
 * Status of the AI approximation
 */
'runtimeStatus'?: string;

/**
 * Output of the AI approximation
 */
'output'?: Array<{

/**
 * Unique identifier of the paragraph in the article
 */
'paragraphId'?: string;

/**
 * List of AI-generated approximation results
 */
'results'?: Array<AiApproxResultItem>;

/**
 * ETag of the resource after updating the paragraph with the AI approximation
 */
'etag'?: string;
}>;

/**
 * Unix timestamp of when the request was created
 */
'createdTime'?: number;

/**
 * Unix timestamp of when the request was last updated
 */
'lastUpdatedTime'?: number;
} => r.body)
    );
  }

  /** Path part for operation `appendHumanApproximation()` */
  static readonly AppendHumanApproximationPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}';

  /**
   * Confirm or append Human approximation.
   *
   * Append approximations to the human approximations of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appendHumanApproximation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appendHumanApproximation$Response(params: AppendHumanApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return appendHumanApproximation(this.http, this.rootUrl, params, context);
  }

  /**
   * Confirm or append Human approximation.
   *
   * Append approximations to the human approximations of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appendHumanApproximation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appendHumanApproximation(params: AppendHumanApproximation$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.appendHumanApproximation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `updateExistingHumanApproximation()` */
  static readonly UpdateExistingHumanApproximationPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/approximation-result/{resultId}';

  /**
   * Update existing human approximation.
   *
   * Append approximations to the human approximations of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExistingHumanApproximation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExistingHumanApproximation$Response(params: UpdateExistingHumanApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return updateExistingHumanApproximation(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing human approximation.
   *
   * Append approximations to the human approximations of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExistingHumanApproximation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExistingHumanApproximation(params: UpdateExistingHumanApproximation$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.updateExistingHumanApproximation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `deleteExistingHumanApproximation()` */
  static readonly DeleteExistingHumanApproximationPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/approximation-result/{resultId}';

  /**
   * Delete existing human approximation.
   *
   * Delete approximations from the human approximations of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExistingHumanApproximation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExistingHumanApproximation$Response(params: DeleteExistingHumanApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return deleteExistingHumanApproximation(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing human approximation.
   *
   * Delete approximations from the human approximations of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteExistingHumanApproximation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExistingHumanApproximation(params: DeleteExistingHumanApproximation$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.deleteExistingHumanApproximation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `getHumanApproximationHistory()` */
  static readonly GetHumanApproximationHistoryPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/history';

  /**
   * Get history of Human approximation.
   *
   * Retrieve the history of human approximations for a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHumanApproximationHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHumanApproximationHistory$Response(params: GetHumanApproximationHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HumanApproxResultItem>>> {
    return getHumanApproximationHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get history of Human approximation.
   *
   * Retrieve the history of human approximations for a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHumanApproximationHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHumanApproximationHistory(params: GetHumanApproximationHistory$Params, context?: HttpContext): Observable<Array<HumanApproxResultItem>> {
    return this.getHumanApproximationHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HumanApproxResultItem>>): Array<HumanApproxResultItem> => r.body)
    );
  }

  /** Path part for operation `setApproximationLevel()` */
  static readonly SetApproximationLevelPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/approximation-level';

  /**
   * Set approximation level.
   *
   * Set the approximation level of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setApproximationLevel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setApproximationLevel$Response(params: SetApproximationLevel$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return setApproximationLevel(this.http, this.rootUrl, params, context);
  }

  /**
   * Set approximation level.
   *
   * Set the approximation level of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setApproximationLevel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setApproximationLevel(params: SetApproximationLevel$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.setApproximationLevel$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `getCommentsOnApproximation()` */
  static readonly GetCommentsOnApproximationPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/comments';

  /**
   * Get comments on the approximation.
   *
   * Retrieve comments on the approximation of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommentsOnApproximation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommentsOnApproximation$Response(params: GetCommentsOnApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApproximationComment>>> {
    return getCommentsOnApproximation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get comments on the approximation.
   *
   * Retrieve comments on the approximation of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCommentsOnApproximation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommentsOnApproximation(params: GetCommentsOnApproximation$Params, context?: HttpContext): Observable<Array<ApproximationComment>> {
    return this.getCommentsOnApproximation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApproximationComment>>): Array<ApproximationComment> => r.body)
    );
  }

  /** Path part for operation `appendCommentToApproximation()` */
  static readonly AppendCommentToApproximationPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/comments';

  /**
   * Append comment to the approximation.
   *
   * Append a comment to the approximation of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appendCommentToApproximation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appendCommentToApproximation$Response(params: AppendCommentToApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return appendCommentToApproximation(this.http, this.rootUrl, params, context);
  }

  /**
   * Append comment to the approximation.
   *
   * Append a comment to the approximation of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appendCommentToApproximation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appendCommentToApproximation(params: AppendCommentToApproximation$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.appendCommentToApproximation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `updateCommentOnApproximation()` */
  static readonly UpdateCommentOnApproximationPath = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/comments/{commentId}';

  /**
   * Update existing comment on the approximation.
   *
   * Update an existing comment to the approximation of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCommentOnApproximation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCommentOnApproximation$Response(params: UpdateCommentOnApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return updateCommentOnApproximation(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing comment on the approximation.
   *
   * Update an existing comment to the approximation of a specific paragraph in an article of an EU Law associated with a specific Project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCommentOnApproximation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCommentOnApproximation(params: UpdateCommentOnApproximation$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.updateCommentOnApproximation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

}
