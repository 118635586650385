/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnsureHttpsInterceptor } from './ensure-https.interceptor';
import { CsrfTokenInterceptor } from './csrf-token.interceptor';
import { AccessTokenInterceptor } from './access-token.interceptor';
import { MaliciousCodeInterceptor } from './malicious-code.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CsrfTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: MaliciousCodeInterceptor, multi: true },
];
