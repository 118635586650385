import { AuthState } from '@core/interfaces/state';
import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';

export const initialAuthState: AuthState = {
  recentUsers: []
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.updateAuth, (state, {fields})=> ({...state, ...fields})),
  on(AuthActions.setUser, (state, { user }) => ({ ...state, user })),
  on(AuthActions.setDmsId, (state, { dms_id }) => ({ ...state, user: { ...state.user!, dms_id }})),
  on(AuthActions.setDmsToken, (state, { csrfToken, sessionId }) => ({ ...state, csrfToken, sessionId})),
  on(AuthActions.setApproxToken, (state, { approxToken }) => ({ ...state, approxToken })),
  on(AuthActions.removeApproxToken, (state) => ({ ...state, approxToken: undefined })),
  on(AuthActions.addRecentUser, (state, {user}) => {
    const recentUsers = state.recentUsers.slice();
    recentUsers.unshift(user);
    if (recentUsers.length > 3) {
      recentUsers.pop(); // Remove the last item if the array length exceeds 3
    }
    return {
      ...state,
      recentUsers
    }
  }),
  on(AuthActions.resetAuth, () => initialAuthState)
);
