/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResourceResponse } from '../../models/resource-response';
import { SaveHumanApproximationRequest } from '../../models/save-human-approximation-request';

export interface UpdateExistingHumanApproximation$Params {

/**
 * The ID of the project
 */
  projectId: string;

/**
 * EU Law ID
 */
  euLawId: string;

/**
 * Article ID
 */
  articleId: string;

/**
 * Paragraph ID
 */
  paragraphId: string;

/**
 * Approximation result ID
 */
  resultId: string;

/**
 * ETag of the resource to be updated
 */
  'If-Match'?: string;
      body: SaveHumanApproximationRequest
}

export function updateExistingHumanApproximation(http: HttpClient, rootUrl: string, params: UpdateExistingHumanApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
  const rb = new RequestBuilder(rootUrl, updateExistingHumanApproximation.PATH, 'put');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('euLawId', params.euLawId, {});
    rb.path('articleId', params.articleId, {});
    rb.path('paragraphId', params.paragraphId, {});
    rb.path('resultId', params.resultId, {});
    rb.header('If-Match', params['If-Match'], {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResourceResponse>;
    })
  );
}

updateExistingHumanApproximation.PATH = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}/paragraphs/{paragraphId}/approximation-result/{resultId}';
