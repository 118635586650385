import { EuLawSummary, ProjectCollaborator, ProjectRequestBody } from '@core/approx-api/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ProjectActions = createActionGroup({
  source: 'Project',
  events: {
    addLaw: props<{law: EuLawSummary}>(),
    removeLaw: props<{id: string}>(),
    addCollaborator: props<{collaborator: ProjectCollaborator}>(),
    setCollaborators: props<{collaborators: ProjectCollaborator[]}>(),
    removeCollaborator: props<{id: string}>(),
    setProps: props<Partial<ProjectRequestBody>>(),
    resetProject: emptyProps(),
  },
});
