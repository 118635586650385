/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApproximationContent } from '../../models/approximation-content';
import { EuLawSummary } from '../../models/eu-law-summary';

export interface GetArticleApproximation$Params {

/**
 * The ID of the project
 */
  projectId: string;

/**
 * EU Law ID
 */
  euLawId: string;

/**
 * Article ID
 */
  articleId: string;
}

export function getArticleApproximation(http: HttpClient, rootUrl: string, params: GetArticleApproximation$Params, context?: HttpContext): Observable<StrictHttpResponse<{

/**
 * Unique identifier of the Article
 */
'id'?: string;

/**
 * The ID of the project
 */
'projectId'?: string;
'euLaw'?: EuLawSummary;

/**
 * Title of the Article
 */
'title'?: string;

/**
 * Display name of the article
 */
'displayName'?: string;

/**
 * Approximation content (paragraphs) for the article
 */
'content'?: Array<ApproximationContent>;
}>> {
  const rb = new RequestBuilder(rootUrl, getArticleApproximation.PATH, 'get');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.path('euLawId', params.euLawId, {});
    rb.path('articleId', params.articleId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      
      /**
       * Unique identifier of the Article
       */
      'id'?: string;
      
      /**
       * The ID of the project
       */
      'projectId'?: string;
      'euLaw'?: EuLawSummary;
      
      /**
       * Title of the Article
       */
      'title'?: string;
      
      /**
       * Display name of the article
       */
      'displayName'?: string;
      
      /**
       * Approximation content (paragraphs) for the article
       */
      'content'?: Array<ApproximationContent>;
      }>;
    })
  );
}

getArticleApproximation.PATH = '/projects/{projectId}/euLaws/{euLawId}/approximations/{articleId}';
