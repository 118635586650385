import { APP_INITIALIZER, Injectable, Provider, Type } from '@angular/core';
import { Store } from '@ngrx/store';
import { $themeConfig } from '../../theme.config';
import { TranslateService } from '@ngx-translate/core';
import { GlobalState, LayoutState } from '@core/interfaces/state';
import { LayoutActions } from '@store/layout';
import { MisAuthService } from './mis-auth.service';
import * as Sentry from '@sentry/angular-ivy';
import { firstValueFrom, lastValueFrom, take } from 'rxjs';

@Injectable()
export class InitService {
  storeData: LayoutState;
  constructor(public translate: TranslateService, public store: Store<GlobalState>, private auth: MisAuthService) {}

  /**
   * The initialization providers to use in `*AppModule`
   * - this concrete {@link InitService}
   * - {@link APP_CONFIG} with optional pre-initialization hook
   * - {@link APP_INITIALIZER}
   * <br>
   * Should only be called on concrete subclasses of InitService for the initialization hooks to work
   */
  public static providers(): Provider[] {
    return [
      {
        provide: InitService,
        useClass: this as unknown as Type<InitService>,
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
      {
        provide: APP_INITIALIZER,
        useFactory: (initService: InitService) => initService.init(),
        deps: [InitService],
        multi: true,
      },
    ];
  }

  protected init(): () => Promise<boolean> {
    return async () => {
      await this.auth.init();
      await this.initStoreData();
      return true;
    };
  }

  async initStoreData() {
    this.storeData = await firstValueFrom(this.store.select((d) => d.layout).pipe(take(1)))
    // set default styles
    let theme: any = localStorage.getItem('theme'); // light, dark, system
    theme = theme || $themeConfig.theme;
    this.store.dispatch(LayoutActions.toggleTheme({ theme }));

    // set language
    let language = localStorage.getItem('i18n_locale'); // en, al
    language = language || $themeConfig.locale;
    const list = this.storeData.languageList;
    const item = list.find((item: any) => item.code === language);
    if (item) {
      await this.toggleLanguage(item);
    }
  }

  async toggleLanguage(item: { code: string; name: string } | undefined) {
    let lang: any = null;
    lang = 'en';
    if (item) {
      lang = item;
    } else {
      let code = this.translate.currentLang || null;
      if (!code) {
        code = localStorage.getItem('i18n_locale');
      }

      item = this.storeData.languageList.find((d: any) => d.code === code);
      if (item) {
        lang = item;
      }
    }

    if (!lang) {
      lang = this.storeData.languageList.find((d: any) => d.code === 'en');
    }

    await lastValueFrom(this.translate.use(lang.code)); // set language
    await lastValueFrom(this.translate.get('INIT')); // load translations
    this.store.dispatch(LayoutActions.toggleLocale({ locale: lang.code }));
    return lang;
  }

  changeAnimation(type = 'add') {
    if (this.storeData.animation) {
      const ele: any = document.querySelector('.animation');
      if (type === 'add') {
        ele?.classList.add('animate__animated');
        ele?.classList.add(this.storeData.animation);
      } else {
        ele?.classList.remove('animate__animated');
        ele?.classList.remove(this.storeData.animation);
      }
    }
  }
}
