import { Injectable } from '@angular/core';
import { HasuraAuthClient, NhostRoleOptions, NhostSession, NhostSessionResponse, SignInParams, User } from '@mis/auth';
import { environment } from 'src/environments/environment';
import { GlobalState } from '@core/interfaces/state';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/auth';
import { AuthRolesEnum } from '@core/api';
import { allowed_roles_claim, chapter_claim, cluster_claim, role_claim } from '@core/interfaces/token';

@Injectable({ providedIn: 'root' })
export class MisAuthService {
  public client: HasuraAuthClient;
  constructor(private store: Store<GlobalState>) {}

  public init(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      this.client = new HasuraAuthClient({
        url: 'https://' + environment.hostAuthDomain,
        refreshIntervalTime: 1800,
        autoRefreshToken: true,
        start: true,
      });
      await this.client.isAuthenticatedAsync();
      resolve(true);
    });
  }

  async signIn(params?: SignInParams) {
    const { session, error } = await this.client.signIn(params);
    if (!!error && !session?.user) return
    this.store.dispatch(AuthActions.setUser({ user: session?.user as any }));
  }

  async refreshSession(options?: NhostRoleOptions): Promise<NhostSessionResponse> {
    const session = this.client.getSession();
    return new Promise<NhostSessionResponse>(async (resolve, reject) => {
      let response: NhostSessionResponse;
      if (session?.refreshToken) {
        response = await this.client.refreshSession(session?.refreshToken, options);
        resolve(response);
      }
      else reject(false)
    });
  }

  get role(): AuthRolesEnum | undefined {
    return this.client.getHasuraClaim(role_claim) as AuthRolesEnum | undefined;
  }

  get chapter(): number | undefined {
    if (!this.client.getHasuraClaim(chapter_claim)) return undefined;
    return Number(this.client.getHasuraClaim(chapter_claim));
  }

  get cluster(): number | undefined {
    if (!this.client.getHasuraClaim(cluster_claim)) return undefined;
    return Number(this.client.getHasuraClaim(cluster_claim));
  }

  get user(): User | null {
    return this.client.getUser();
  }

  get session(): NhostSession | null {
    return this.client.getSession();
  }

  get allowed_roles() {
    return this.client.getHasuraClaim(allowed_roles_claim) as AuthRolesEnum[] | undefined;
  }
}
