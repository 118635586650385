import { PaperlessUser } from './paperless-user'

export interface PaperlessUiSettings {
  user: PaperlessUser
  settings: Object
  permissions: string[]
}

export interface PaperlessUiSetting {
  key: string
  type: string
  default: any
}

export const SETTINGS_KEYS = {
  NOTES_ENABLED: 'general-settings:notes-enabled',
  USE_NATIVE_PDF_VIEWER: 'general-settings:document-details:native-pdf-viewer',
  DEFAULT_PERMS_OWNER: 'general-settings:permissions:default-owner',
  DEFAULT_PERMS_VIEW_USERS: 'general-settings:permissions:default-view-users',
  DEFAULT_PERMS_VIEW_GROUPS: 'general-settings:permissions:default-view-groups',
  DEFAULT_PERMS_EDIT_USERS: 'general-settings:permissions:default-edit-users',
  DEFAULT_PERMS_EDIT_GROUPS: 'general-settings:permissions:default-edit-groups',
}

export const SETTINGS: PaperlessUiSetting[] = [
  {
    key: SETTINGS_KEYS.DEFAULT_PERMS_OWNER,
    type: 'number',
    default: undefined,
  },
  {
    key: SETTINGS_KEYS.NOTES_ENABLED,
    type: 'boolean',
    default: true,
  },
  {
    key: SETTINGS_KEYS.DEFAULT_PERMS_VIEW_USERS,
    type: 'array',
    default: [],
  },
  {
    key: SETTINGS_KEYS.USE_NATIVE_PDF_VIEWER,
    type: 'boolean',
    default: true,
  },
  {
    key: SETTINGS_KEYS.DEFAULT_PERMS_VIEW_GROUPS,
    type: 'array',
    default: [],
  },
  {
    key: SETTINGS_KEYS.DEFAULT_PERMS_EDIT_USERS,
    type: 'array',
    default: [],
  },
  {
    key: SETTINGS_KEYS.DEFAULT_PERMS_EDIT_GROUPS,
    type: 'array',
    default: [],
  },
]
